.section#project {
    padding-top: 100px;
    font-size: 20px;
    color: #fff;
    opacity: 1;
    animation: fadeIn .5s .5s 1 both;
}

.section#project a {
    color: #fff;
    text-decoration: none;
}

.tags, .links {
    margin: 10px -5px;
}

.description {
    margin: 20px 0 10px;
    line-height: 1.3em;
}

.assets {
  margin: 20px 0 10px;
  height: 450px;
  overflow-y: hidden;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  color: #000;
  text-align: center;
}

.assets img {
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, .25);
  vertical-align: top;
  margin: 5% 15px 0;
  transition: .5s margin-top;
  /*max-width: 95%;*/
}

.assets img:nth-child(even) {
  margin-top: -5%;
}

.assets:hover img {
    margin-top: 25px;
}

.feature {
    margin: 20px 0 10px;
}

.feature i {
    border: 1px solid;
    padding: 10px;
    border-radius: 50%;
    margin: -10px 10px -10px -10px;
    min-width: 42px;
    text-align: center;
    transition: .3s all;
    animation: iconEmph 1s linear 1.5s 1;
}

.tag {
    display: inline-block;
    /*border: solid 1px;*/
    background: rgba(0, 0, 0, .2);
    opacity: .7;
    padding: 5px 10px;
    border-radius: 10px;
    margin: 5px;
    font-size: 15px;
}

.tag i {
    margin-right: 10px;
}

.award {
    font-size: 16px;
    border-radius: 0;
}

.link {
    transition: .3s all;
    display: inline-block;
    border: solid 1px;
    /*padding: 5px 10px;*/
    border-radius: 10px;
    margin: 5px;
    font-size: 16px;
}

.link:hover {
    background: rgba(255, 255, 255, .2);
}

.link i {
    padding: 5px 10px;
}

.link .title {
    display: inline-block;
    padding: 5px 10px;
    border-left: 1px solid;
    /* text-decoration: underline; */
}

.link .url {
    font-size: 15px;
    opacity: .75;
}

.link .url i {
    margin-left: 5px;
}

.actions {
  position: absolute;
  top: 10px;
  right: 10px;
}

.actions .action {
  font-size: 30px;
  margin: 10px;
  padding: 0 5px;
  color: #fff;
  cursor: pointer;
  transform: scale(.9);
  transition: .3s all;
}

.actions .action:hover {
  transform: scale(1);
}

@keyframes iconEmph {
  0% { transform: rotate(0deg) scale(1); }
  20% { transform: rotate(15deg) scale(1.02); }
  40% { transform: rotate(-15deg) scale(1.03); }
  60% { transform: rotate(15deg) scale(1.03); }
  80% { transform: rotate(-15deg) scale(1.02); }
  100% { transform: rotate(0deg) scale(1); }
}

@media screen and (max-width: 950px) {
  .section#project h1 {
    text-align: left;
  }

  .assets {
    border: 1px solid #fff;
  }
}

@media screen and (max-width: 725px) {
  .section#project {
      padding: 50px 20px;
  }

  .link {
      width: 100%;
      margin: 5px 0;
      min-height: auto;
  }

  .link .title {
      padding: 10px;
  }

  .feature {
    min-height: 42px;
    padding-left: 52px;
  }

  .feature i {
    float: left;
    margin: 0;
    margin-left: -52px;
  }
}