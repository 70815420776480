@charset "utf-8";
/* CSS Document */

@font-face {
  font-family: 'Raleway';
  src: url('./assets/raleway/raleway-regular-webfont.eot'); /* IE9 Compat Modes */
  src: url('./assets/raleway/raleway-regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/raleway/raleway-regular-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('./assets/raleway/raleway-regular-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./assets/raleway/raleway-regular-webfont.svg') format('svg'); /* Legacy iOS */
  font-weight: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./assets/raleway/raleway-bold-webfont.eot'); /* IE9 Compat Modes */
  src: url('./assets/raleway/raleway-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/raleway/raleway-bold-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('./assets/raleway/raleway-bold-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./assets/raleway/raleway-bold-webfont.svg') format('svg'); /* Legacy iOS */
  font-weight: 800;
}

@font-face {
  font-family: 'Raleway';
  src: url('./assets/raleway/raleway-light-webfont.eot'); /* IE9 Compat Modes */
  src: url('./assets/raleway/raleway-light-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/raleway/raleway-light-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('./assets/raleway/raleway-light-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./assets/raleway/raleway-light-webfont.svg') format('svg'); /* Legacy iOS */
  font-weight: 300;
}

*, *:before, *:after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}

body{
  background: #fafafa;
  transition: .5s all;
  color: #333;
  font-family: 'Raleway', sans-serif;
  margin: 0;
  padding: 0;
}

.main {
  position: absolute;
  width: 100%;
  height: 100%;
}

.container {
  width: 95%;
  max-width: 900px;
  margin: 0 auto;
}

.container.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.section {
  width: 100%;
  transition: all 1s;
  padding: 25px 0;
}

.section#fold:first-of-type {
  height: 80%;
}

.animation .section#fold:first-of-type {
  animation: shrinkFoldHeight .7s 12s 1 both;
}

.section:last-of-type {
  padding-bottom: 50px;
}

/*#particles-js{
  position:absolute;
  width: 100%;
  height: 100%;
}*/

.centered {
  position: absolute;
  width: 600px;
  text-align: center;
  left: 50%;
  top: 40%;
  margin-left: -300px;
  margin-top: -175px;
  transition: all 1s;
  line-height: 1.5;
}

.animation .centered {
  top: 50%;
  animation: shrinkFoldTop .7s 12s 1 both;
}

#fold .subtitle {
  display: none;
}

.animation #fold .subtitle:nth-of-type(2), .animationEnded #fold .subtitle:nth-of-type(3) {
  display: block;
}

/* .animation .social {
  animation: fadeInLight .7s 11.5s 1 both;
} */

.social {
  opacity: .85;
  font-size: 25px;
}

.social a {
  color: #333;
  margin: 0 10px;
}

.social a i {
  transform: scale(1);
  transition: all .3s;
}

.social a:hover i {
  transform: scale(1.15);
}

.logo {
  width: 600px;
  max-width: 100%;
  margin: 75px 0;
}

.subtitle {
  opacity: .85;
  font-size: 24px;
  text-align: center;
}

.typed-cursor {
  opacity: 1;
  animation: blink 0.8s infinite;  
}

.subtitle .typed-cursor {
  font-size: 30px;
  padding: 0;
  margin-right: -6px;
  margin-top: -5px;
  display: inline-block;
}

.aboutBtn {
    opacity: .75;
    display: inline-block;
    padding: 10px 15px;
    text-transform: lowercase;
    color: initial;
}

.animation .aboutBtn {
  animation: fadeInLight .7s 11.5s 1 both;
}

#projects, #blog {
  animation: fadeIn .5s 0s 1 both;
}

.aboutBtn:before {
  content: '[';
  transition: .3s all;
}

.aboutBtn:after {
  content: ']';
  transition: .3s all;
}

.aboutBtn:hover:before, .aboutBtn:hover:after {
  margin: 0 5px;
}

h2 {
  font-size: 40px;
  margin-left: -25px;
  grid-column: span 4;
}

.project {
  min-height: 215px;
  color: #FFF;
  padding: 30px 15px;
  opacity: .9;
  transform: scale(1);
  transition: all .3s;
  background: #666;
  text-decoration: none;
}

.project.large {
  grid-column: span 2;
}

.project.post {
  padding: 30px 20px;
  grid-column: span 2;
  height: auto;
}

.project:hover {
    opacity: 1;
    transform: scale(1.03);
}

.project h3 {
    margin: 5px 0;
    font-size: 24px;
}

.project .description {
    text-transform: lowercase;
}

.project.post .description {
    text-transform: initial;
}

.project .type {
    font-size: smaller;
    display: block;
    text-transform: lowercase;
    margin-top: 15px;
}

.project .type i {
  margin-left: 5px;
}

.project a {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@keyframes blink {
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInLight {
  from { opacity: 0; }
  to { opacity: 0.8; }
}

@keyframes shrinkFoldHeight {
  0% { height: 100%; }
  100% { height: 80%; }
}

@keyframes shrinkFoldTop {
    0% { top: 50%; }
    100% { top: 40%; }
}

@media screen and (max-width: 950px) {
  h2 {
    margin-left: 0;
    text-align: center;
    grid-column: span 3;
  }

  .container.grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .project.post {
    grid-column: span 3;
    min-height: unset;
  }
}

@media screen and (max-width: 725px) {
  h2, .project.post {
    grid-column: span 2;
  }

  .container.grid {
    grid-template-columns: 1fr 1fr;
  }

  .centered {
    width: 90%;
    top: 40%;
    margin-top: -100px;
    left: 0;
    margin-left: 5%;
  }

  .animation .centered {
    top: 45%;
  }

  .logo {
    margin: 0;
  }
  
  .social {
    margin-bottom: 20px;
  }
  
  .subtitle {
    margin-top: 20px;
    font-size: 20px;
    line-height: 30px;
  }
  
  .subtitle .typed-cursor {
    font-size: 25px;
  }

  #projects {
      margin-top: -25px;
  }
}


@media screen and (max-width: 425px) {
  h2, .project.post {
    grid-column: span 1;
  }

  .container.grid {
    grid-template-columns: 1fr;
  }

  .project {
    min-height: unset;
  }

  .project.large {
    grid-column: span 1;
  }

  .centered {
    top: 40%;
    margin-top: -100px;
  }

  .logo {
    margin: 0;
  }
  
  .social {
    margin-bottom: 20px;
  }
  
  .subtitle {
    margin-top: 20px;
    font-size: 20px;
    line-height: 30px;
  }
  
  .subtitle .typed-cursor {
    font-size: 25px;
  }

  #projects {
      margin-top: -25px;
  }
}