#about #fold .subtitle {
  display: block !important;
}

p {
    font-size: 20px;
    opacity: .75;
    animation: fadeInLight .7s .5s 1 both;
    text-align: left;
}

p a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px dashed;
}

p a:hover {
    background: #f7c600;
}

.backBtn {
    display: inline-block;
    padding: 5px;
    transition: .3s all;
    margin-bottom: 50px;
    text-decoration: none;
    font-size: 20px;
}

.backBtn i {
    margin-right: 5px;
    transition: .3s all;
}

.backBtn:hover {
    padding-left: 0;
}

.backBtn:hover i {
    margin-right: 10px;
}

